import * as React from 'react';
import { useStaticQuery, Link, graphql } from 'gatsby';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLinkedinIn,
  faFacebookF,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { getCookie, deleteCookie } from '../lib/utils';

function Footer({ className }) {
  library.add(faLinkedinIn, faFacebookF, faInstagram, faLocationDot);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            footer {
              menuLinks {
                title
                links {
                  name
                  link
                  icon
                }
              }
              additionalText
            }
          }
        }
      }
    `,
  );

  let legals = site.siteMetadata.footer.menuLinks.filter(
    (item) => item.title === 'Legal',
  );
  let socials = site.siteMetadata.footer.menuLinks.filter(
    (item) => item.title === 'Social',
  );
  let locations = site.siteMetadata.footer.menuLinks.filter(
    (item) => item.title === 'Locations',
  );

  legals = legals.length ? legals[0] : null;
  socials = socials.length ? socials[0] : null;
  locations = locations.length ? locations[0] : null;

  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  function openCookieSettings() {
    Object.keys(window.gatsbyPluginGDPRCookiesOptions).forEach((key) => {
      if (key !== 'environemnts' && key !== 'plugins' && key) {
        localStorage.removeItem(
          window.gatsbyPluginGDPRCookiesOptions[key].cookieName,
        );
        if (
          getCookie(window.gatsbyPluginGDPRCookiesOptions[key].cookieName)
          !== ''
        ) {
          localStorage.setItem(
            window.gatsbyPluginGDPRCookiesOptions[key].cookieName,
            getCookie(window.gatsbyPluginGDPRCookiesOptions[key].cookieName),
          );
        }
        deleteCookie(window.gatsbyPluginGDPRCookiesOptions[key].cookieName);
      }
    });
    window.location.reload();
  }

  return (
    <>
      {/* <Script src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=UjZT3H" strategy="idle" /> */}
      <footer
        className={`bg-background-light dark:bg-background-footer-light md:dark:bg-background-footer-dark border-t border-latori-pink transition-all ${className}`}
      >
        <div className="px-4 lg:px-8 text-center lg:text-left">
          <div className="mx-auto max-w-screen-2xl py-10 text-center md:text-left">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 lg:gap-8">
              <div className="flex justify-start md:justify-start flex-col gap-4 md:max-w-xs text-left">
                Newsletter
                <div
                  className="klaviyo-form-Yfb5Tc min-h-[50px]"
                  data-klaviyo-accepted={
                    mounted ? getCookie('gatsby-gdpr-klaviyo') === 'true' : ''
                  }
                  data-text="Bitte stimmen Sie erst den Cookies zu, um das Newsletter-Formular zu laden."
                />
              </div>
              <div className="row-start-4 md:row-start-auto">
                {socials && (
                  <ul className="flex flex-row justify-center md:justify-end lg:justify-center gap-4 items-end h-full">
                    {socials.links.map((social) => (
                      <li key={social.name}>
                        <a
                          href={social.link}
                          title={social.name}
                          target="_blank"
                          rel="noreferrer"
                          className={clsx(
                            social.icon === 'facebook-f'
                              ? ' pt-[0.35rem] !text-xl'
                              : '',
                            'rounded-full overflow-hidden bg-background-dark dark:bg-white text-white dark:text-background-dark w-6 h-6 flex justify-center items-center text-sm',
                          )}
                        >
                          <FontAwesomeIcon
                            icon={icon({
                              prefix: 'fab',
                              iconName: social.icon,
                            })}
                          />
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="flex flex-col gap-4 justify-center md:justify-start lg:justify-end lg:items-end md:col-span-2 lg:col-span-1 h-full">
                <div className="flex gap-6 justify-center items-center">
                  <img
                    src="/assets/images/shopifyexperts-dark.png"
                    width="178"
                    height="32"
                    alt="Shop Usability Award"
                    loading="lazy"
                    className="h-8 hidden dark:block"
                  />
                  <img
                    src="/assets/images/shopifyexperts-light.png"
                    width="178"
                    height="32"
                    alt="Shop Usability Award"
                    loading="lazy"
                    className="h-8 dark:hidden"
                  />
                  <Link
                    to="/references/shape-republic"
                    className="flex-grow flex-shrink-0 basis-auto"
                  >
                    <img
                      src="/assets/images/logo-shop-usability-award.png"
                      width="80"
                      height="80"
                      alt="Shop Usability Award"
                      loading="lazy"
                      className="h-20"
                    />
                  </Link>
                </div>
              </div>
              {legals && (
                <ul className="flex justify-center md:justify-end lg:justify-center items-start lg:col-start-2 flex-wrap gap-y-4">
                  {legals.links.map((item, index) => (
                    <li
                      className={`pr-2 border-background-dark dark:border-white leading-none${
                        legals.links.length - 1 > index
                          ? ' border-r-2 pl-2'
                          : ''
                      }${
                        legals.links.length - 1 === index ? ' pl-2 pr-0' : ''
                      }`}
                      key={item.name}
                    >
                      {item.link === '#cookie-banner' ? (
                        <button
                          type="button"
                          className="cursor-pointer"
                          onClick={openCookieSettings}
                        >
                          Cookie-Einstellungen
                        </button>
                      ) : (
                        <Link to={item.link}>{item.name}</Link>
                      )}
                    </li>
                  ))}
                </ul>
              )}
              {locations && (
                <ul className="flex justify-center lg:justify-end lg:col-start-3 items-start flex-wrap gap-y-4">
                  {locations.links.map((location, index) => (
                    <li
                      className={`flex gap-2 pr-4 border-background-dark dark:border-white leading-none${
                        locations.links.length - 1 > index ? ' border-r-2' : ''
                      }${
                        locations.links.length - 1 === index ? ' pl-4 pr-0' : ''
                      }`}
                      key={location.name}
                    >
                      <Link to="/about-us#locations">
                        <FontAwesomeIcon icon={icon(faLocationDot)} />
                        {' '}
                        {location.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
              <div className="mx-auto w-full flex justify-center lg:col-span-3 items-center">
                <Link to="/all-blog" className="inline-flex text-xs">
                  Alle Blogbeiträge
                </Link>
              </div>
              <div className="mx-auto md:col-span-2 lg:col-span-3 text-xs text-center opacity-50">
                {site.siteMetadata.footer.additionalText}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
