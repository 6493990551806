const siteUrl = 'https://www.latori.com';
module.exports = {
  trailingSlash: 'never',
  siteMetadata: {
    title: 'Latori GmbH',
    description: '✅ Wir waren die erste Shopify Agentur für Shopify Plus in Deutschland! Ihr Shopify Partner für ✓ Shop-Erstellung ✓ Migration ✓ Programmierung ✓ Design.',
    siteUrl,
    author: {
      name: 'Latori GmbH',
    },
    social: {
      twitter: 'latori-gmbh',
    },
    intro: {
      title: 'Über uns',
      text: 'Wir, das Team der Latori GmbH, sind eine spezialisierte Shopify Agentur mit Sitz in Wuppertal und Stuttgart. Als Experten für individuelle Softwareentwicklung, Design, Shop-Setup und Migration betreuen wir komplexe Projekte mit Fokus auf Shopify und Shopify Plus. Namhafte E-Commerce-Unternehmen wie Sennheiser, Mister Spex oder Live Fast Die Young vertrauen auf unsere über 10-jährige Erfahrung in der Entwicklung und Optimierung. Unser Ziel als Ihre Shopify Agentur: den E-Commerce für Sie so einfach wie möglich zu machen und Ihren Alltag mit unseren zukunftssicheren Lösungen zu erleichtern. Wir sind bereit für Ihre Herausforderungen.',
      claim: 'simply. better. commerce.',
      link: {
        url: '/',
        label: 'Mehr',
      },
    },
    textImageScrollSlider: [
      {
        title: 'Darauf sind wir stolz',
        subTitle: 'Shop Usability Award: AGENTUR DES JAHRES 2023!',
        description: 'Wir sind übermäßig stolz und dankbar, dass wir zur Agentur des Jahres 2023 gekürt wurden. Ein großes Dankeschön geht an die K5, den Shop Usability Award aber vor allem an das gesamte Latori-Team aus Shopify Experten für diese herausragende Leistung. 🥳',
        link: {
          url: '/blogpost/shop-usability-award',
          text: 'Mehr erfahren',
        },
      },
      {
        title: '',
        subTitle: 'SHOP USABILITY AWARD: ASPHALTGOLD GEWINNT DEN SUA!',
        description: 'Der Shopify Onlineshop der Streetwear- und Lifestyle-Brand Asphaltgold konnte sich den Sieg in der Kategorie Fashion & Accessoires sichern. 🎉',
        link: {
          url: '',
          text: '',
        },
      },
      {
        title: '',
        subTitle: 'SHOP USABILITY AWARD: LATORI-KUNDE SENNHEISER RÄUMT AB!',
        description: 'Sennheiser stellte sich der Konkurrenz in der Kategorie Electronics & Entertainment - mit Erfolg! 🏆',
        link: {
          url: '',
          text: '',
        },
      },
      {
        title: '',
        subTitle: 'Shop Usability Award: SHEKO SETZT SICH DURCH!',
        description: 'Auch SHEKO lässt die Konkurrenz hinter sich und holt sich den 1. Platz in der Kategorie FCMG. 🥇',
        link: {
          url: '',
          text: '',
        },
      },
    ],
    seoTextSection: {
      title: 'Warum wir auf Shopify setzen',
      subtitle: 'Unser System des Vertrauens für individuelle D2C und B2B Projekte',
      texts: [
        'Als zertifizierte Shopify Agentur brennen wir für dieses Shopsystem. Warum? Die cloudbasierte All-in-One-Lösung will den Handel für jedes Unternehmen besser machen. Dieses Versprechen wird durch ein intuitives, flexibles und verlässliches System mit zahlreichen Schnittstellen erfüllt. Unternehmen profitieren beim Einsatz der Software-as-a-Service-Lösung von vielen Vorteilen: Wer Shopify oder Shopify Plus im Einsatz hat, muss zukünftig keine Systemwechsel, kostspieligen Updates oder Backups mehr befürchten. Das macht das System automatisch. Auch das Hosting wird übernommen. Darüber hinaus bietet Shopify integrierte Marketingtools, um den Umsatz zu steigern und Kunden zu binden. Mit einer sicheren und zuverlässigen Infrastruktur können Marken außerdem auf ein skalierbares System zugreifen, das mit ihrem Wachstum mitwächst.',
        'Die Plattform bietet Marken jeder Größe eine selbsterklärende Usability, vorgefertigte Themes, eine Vielzahl von Designs und einen umfangreichen App Store, für den individuellen Aufbau Ihres eigenen Onlineshops. Setzen Sie Ihr Produkt in einem professionellen Onlineshop in Szene - Shopify bietet die perfekte Basis dafür.',
        'Wenn es dann doch noch etwas individueller sein darf, gibt es Latori - eine von wenigen spezialisierten Shopify Agenturen in Deutschland. Wir realisieren auf unserer Seite die Erstellung Ihres Shops, kümmern uns um zusätzliche Entwicklungen Ihrer Onlineshop-Wünsche, nehmen die Umsetzung eines responsiven und verkaufsstarken Designs in die Hand und unterstützen Sie bei der Konzeption und Optimierung Ihres Onlinestores. Dabei setzen wir auch auf die Zusammenarbeit mit starken Partnern, die für Ihre Marke Marketing- und Optimierungs-Services anbieten. So wird Ihr Projekt auch zu unserem.',
        'Beinahe 3 Millionen Händler auf der ganzen Welt setzen mittlerweile auf die Lösung, darunter sowohl zahlreiche etablierte Marken wie Sennheiser, Vogue oder Babbel als auch junge D2C-Marken wie ooia, Asphaltgold oder Live Fast Die Young. Als Shopify Experten betreuen wir genau die Onlineshops dieser Marken.',
        'Dass wir als Shopify Agentur eine gute Wahl sind, beweisen auch unsere Auszeichnungen. Im Jahr 2023 wurden wir als Agentur des Jahres beim Shop Usability Award ausgezeichnet, zudem erhielten auch drei unserer Kunden diese begehrte Auszeichnung für ihren Shop.',
        'Konzentrieren Sie sich auf das, was Sie am besten können: Erstklassige Produkte zu erstellen und zu vertreiben. Um den Rest kümmern wir uns als zertifizierte Shopify Agentur gern für Sie!',
      ],
    },
    footer: {
      menuLinks: [
        {
          title: 'Social',
          links: [
            {
              name: 'Linkedin',
              link: 'https://www.linkedin.com/company/latori/',
              icon: 'linkedin-in',
            },
            {
              name: 'Facebook',
              link: 'https://www.facebook.com/LatoriSoftware',
              icon: 'facebook-f',
            },
            {
              name: 'Instagram',
              link: 'https://www.instagram.com/latori_gmbh/',
              icon: 'instagram',
            },
          ],
        }, {
          title: 'Legal',
          links: [
            {
              name: 'Impressum',
              link: '/imprint',
            },
            {
              name: 'Datenschutz',
              link: '/privacy',
            },
            {
              name: 'Cookie-Einstellungen',
              link: '#cookie-banner',
            },
          ],
        },
        {
          title: 'Locations',
          links: [
            {
              name: 'Wuppertal',
              link: '/wuppertal',
            },
            {
              name: 'Stuttgart & Umgebung',
              link: '/stuttgart',
            },
          ],
        },
      ],
      additionalText: 'Wir schätzen alle unsere Kunden, Nutzer und Leser, egal ob weiblich, männlich, divers oder nicht-binär. Der Lesbarkeit halber verzichten wir auf Gendersternchen und nutzen weiterhin das generische Maskulinum. Wir sprechen damit ausdrücklich alle an. Bitte beachten Sie außerdem, dass wir Zitate zum besseren, sprachlichen Verständnis leicht angepasst haben.',
    },
    consent: {
      title: 'Privatsphäre-Einstellungen',
      description: 'Diese Seite nutzt Website-Tracking-Technologien von Dritten, um ihre Dienste anzubieten, stetig zu verbessern und Werbung entsprechend der Nutzer anzuzeigen. Sie können unsere Seite grundsätzlich auch ohne das Setzen von Cookies besuchen und Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen oder ändern.',
      imprint: 'Impressum',
      privacy: 'Datenschutzerklärung',
    },
  },
  plugins: [
    'gatsby-transformer-yaml',
    'gatsby-plugin-react-helmet',
    'gatsby-transformer-sharp',
    {
      resolve: 'gatsby-plugin-gatsby-cloud',
      options: {
        headers: {
          '/*': [
            'X-Frame-Options: ALLOW',
          ],
        },
      },
    },
    {
      resolve: 'gatsby-plugin-sharp',
      options: {
        // Defaults used for gatsbyImageData and StaticImage
        defaults: {},
        // Relates to "options.failOn" in https://sharp.pixelplumbing.com/api-constructor#parameters
        failOn: 'warning',
      },
    },
    {
      resolve: 'gatsby-plugin-layout',
      options: {
        component: require.resolve('./src/components/layout.js'),
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/content/blog`,
        name: 'blog',
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/content/data/`,
        name: 'data',
      },
    },
    {
      resolve: 'gatsby-plugin-sitemap',
      options: {
        output: '/',
        query: `
          {
            allSitePage {
              nodes {
                path
              }
            }
            allMarkdownRemark(
              filter: { fileAbsolutePath: { regex: "/(blog)/" }}
              sort: { fields: [frontmatter___date], order: ASC }
              limit: 1000
            ) {
              nodes {
                id
                frontmatter {
                  permalink
                  date
                }
              }
            }
          }
        `,
        resolveSiteUrl: () => siteUrl,
        resolvePages: ({
          allSitePage: { nodes: allPages },
          allMarkdownRemark: { nodes: allMarkdownNodes },
        }) => {
          const markdownNodeMap = allMarkdownNodes.reduce((acc, node) => {
            const { permalink } = node.frontmatter;
            acc[permalink] = node;

            return acc;
          }, {});

          return allPages.map((page) => ({ ...page, ...markdownNodeMap[page.path] }));
        },
        serialize: ({ path, frontmatter }) => ({
          url: path,
          lastmod: frontmatter != null ? frontmatter.date : null,
          priority: 0.7,
          changefreq: 'daily',
        }),
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'images',
        path: `${__dirname}/src/images`,
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        name: 'fonts',
        path: `${__dirname}/src/fonts`,
      },
    },
    {
      resolve: 'gatsby-transformer-remark',
      options: {
        plugins: [
          {
            resolve: 'gatsby-remark-images',
            options: {
              maxWidth: 630,
            },
          },
          {
            resolve: 'gatsby-remark-responsive-iframe',
            options: {
              wrapperStyle: 'margin-bottom: 1.0725rem',
            },
          },
          'gatsby-remark-autolink-headers',
          'gatsby-remark-prismjs',
          'gatsby-remark-copy-linked-files',
          'gatsby-remark-smartypants',
        ],
      },
    },
    {
      resolve: 'gatsby-plugin-react-svg',
      options: {
        rule: {
          include: /assets/,
        },
      },
    },
    'gatsby-plugin-postcss',
    'gatsby-plugin-image',
    {
      resolve: 'gatsby-plugin-gdpr-cookies',
      options: {
        googleAnalytics: {
          trackingId: 'G-WF2DPMKGWD',
          anonymize: true,
        },
        klaviyo: {
          cookieName: 'gatsby-gdpr-klaviyo',
          trackingId: 'UjZT3H',
        },
        provenExpert: {
          cookieName: 'gatsby-gdpr-proven-expert',
          url: 'https://www.provenexpert.com/widget/bar_latori-gmbh.js',
        },
        linkedin: {
          cookieName: 'gatsby-gdpr-linked-in',
          trackingId: '2098914',
        },
        facebookPixel: {
          cookieName: 'gatsby-gdpr-facebook-pixel',
          pixelId: '161569961359773',
        },
        inspectlet: {
          cookieName: 'gatsby-gdpr-inspectlet-pixel',
          pixelId: '1522785580',
        },
        // Defines the environments where
        // the tracking should be available  - default is ["production"]
        environments: ['production'],
      },
    },
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        icon: 'src/assets/logo-square.svg',
      },
    },
    {
      resolve: 'gatsby-source-personio-xml',
      options: {
        url: 'https://latori.jobs.personio.de/xml?language=de',
      },
    },
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: 't9DFrGNLE0hGttjZo4UfhQtt',
        cache: {
          clear: 'auto',
          type: 'memory',
        },
        resolveRelations: [
          'blogpost.author',
        ],
        version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
        // Optional parameter to download the images to use with Gatsby Image Plugin
        localAssets: false,
        // languages: ['de', 'at']
        // Optional parameter. Omission will retrieve all languages by default.
        resolveLinks: 'story',
      },
    },
    {
      resolve: 'gatsby-plugin-local-search',
      options: {
        name: 'blog',
        engine: 'flexsearch',
        query: `
          {
            allStoryblokEntry(
              filter: {
                field_component: { eq: "blogpost" }
                lang: { eq: "default" }
              }
              sort: { fields: first_published_at, order: DESC }
            ) {
              edges {
                node {
                  id
                  internalId
                  uuid
                  name
                  content
                  full_slug
                  lang
                  first_published_at
                  slug
                  tag_list
                  translated_slugs {
                    lang
                    name
                  }
                }
              }
            }
          }
        `,
        ref: 'slug',
        index: ['title'],
        store: ['title', 'slug', 'name', 'full_slug', 'hero'],
        normalizer: ({ data }) => data.allStoryblokEntry.edges
          .map(({ node }) => {
            let hero = null;
            try {
              hero = JSON.parse(node.content).hero.filename;
            } catch (error) {
              console.error(new Error('Could not parse hero image'));
            }
            return {
              ...node,
              title: node.name || node.slug,
              hero,
            };
          }),
      },
    },
    {
      resolve: 'gatsby-plugin-canonical-urls',
      options: {
        siteUrl: 'https://www.latori.com',
        stripQueryString: true,
      },
    },
  ],
};
